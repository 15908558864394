import { IFieldValueUser } from 'sp';
import { ImageCacheService } from './ImageCacheService';
import { UserInfoService } from './UserInfoService';

export class UserInfoPhotoService {
  private static ongoingRequests: Map<string, Promise<string>> = new Map();

  public static async cacheUserPictures(assignedTo: IFieldValueUser[]) {
    await Promise.all(assignedTo.map(async (user) => await this.getUserPhotoUrl(user.guid || user.userName)));
  }

  public static getCachedUserPhotoUrl(rawUserObjectId: string) {
    if (!rawUserObjectId)
      return null;

    const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);
    const imageUrl = `visplan://photo/${userObjectId}`;
    return ImageCacheService.getCachedImageUrl(imageUrl);
  }

  public static async getUserPhotoUrl(rawUserObjectId: string) {
    if (rawUserObjectId) {
      const userObjectId = UserInfoService.encodeUserObjectId(rawUserObjectId);

      if (this.ongoingRequests.has(userObjectId)) {
        return this.ongoingRequests.get(userObjectId);
      }

      const imageUrl = `visplan://photo/${userObjectId}`;
      const requestPromise = ImageCacheService.getImageAsync(imageUrl, () => {
        const graph = UserInfoService.getClient(userObjectId);
        return graph.users.getById(userObjectId).photo.getBlob();
      });

      this.ongoingRequests.set(userObjectId, requestPromise);

      try {
        const result = await requestPromise;
        return result;
      } finally {
        this.ongoingRequests.delete(userObjectId);
      }
    }
  }
}
