import { IVistoListItemWithProgress, getListDefinition, VistoKind, ListItemUpdater, IVistoPlan, IVistoListItem, IVistoPlanSettings } from 'sp';
import { StorageService } from 'services/StorageService';
import { IOperationOptions } from 'services/IOperationOptions';
import { Operation } from 'shared/Operation';
import { ChangesService } from 'services/ChangesService';
import { IItemChanges } from 'services/Interfaces';
import { INotify, notifyInfoBar, NotificationType, clearInfoBar } from 'services/Notify';
import { trackClient } from 'shared/clientTelemetry';
import { PlanDataService } from 'services/PlanDataService';
import { IProgressData } from 'services/IProgressData';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { AuthService } from 'services/AuthService';
import { IntegrationService } from 'services/IntegrationService';
import { IIconInfo } from 'services/IIconInfo';
import { Commands } from 'services/Commands';
import { CommandName } from 'shared/CommandName';

export class SharepointExternalDataService {

  public static makeBreakVisPlanLinkNotificationAction(plan: IVistoPlan, item: IVistoListItemWithProgress, notify: INotify) {
    return {
      title: TextService.format(strings.VisPlanNotification_BreakLinkTitle),
      command: Commands.makeBreakLinkAction(plan, item, true, CommandName.BreakLinkVisPlan, notify),
      confirmation: {
        buttonOkText: TextService.format(strings.ButtonBreak),
        buttonOkBusyText: TextService.format(strings.ButtonBreaking),
        buttonCancelText: TextService.format(strings.ButtonCancel),
        title: TextService.format(strings.VisPlanNotification_BreakVisPlanLink),
        content: TextService.format(strings.VisPlanNotification_BreakVisPlanLinkDescription, { title: TextService.formatTitle(item, plan.items) }),
      }
    };
  }

  public static configure() {

    IntegrationService.hooks['lists'] = {

      isRecognizedLink: url => {
        return this.isLinkedItem(url);
      },
      getLinkName: async (url) => {
        if (this.isLinkedItem(url)) {
          const parsed = this.parseSourceUrl(url);

          const sp = AuthService.getSpClient(parsed.webUrl);
          const spItem = await sp.web.getList(parsed.listUrl).items.getById(parsed.itemId)();

          return TextService.format(strings.LinkName_VisPlan, { linkKind: TextService.getVistoKindName(parsed.listKind), spItemTitle: spItem.Title });
        }
      },
      allowRecalculation: (name: keyof IProgressData) => {
        return false;
      },
      allowEdit: (name: keyof IProgressData) => {
        return name !== 'startDate' && name !== 'endDate' && name !== 'percentComplete' && name !== 'plannedPercentComplete';
      },
      getBrowserLink: (url) => {
        try {
          const parsed = new URL(url);
          const deepLink = parsed.searchParams.get('deepLink');
          if (deepLink) {
            return deepLink;
          }
        } catch (err) {
          trackClient.error(`Invalid deep link`, err);
        }
        return url;
      },

      getSyncDate: (settings: IVistoPlanSettings): Date => {
        return undefined;
      },

      getIconInfo: async (url: string): Promise<IIconInfo> => {
        return {
          iconUrl: require('static/assets/links/visplan.svg'),
          tooltipText: TextService.format(strings.LinkIconTitle_VisPlan)
        };
      },

      getDashbordRef: async (url: string) => {
        const siteUrl = SharepointExternalDataService.getSiteUrl(url);
        const planId = SharepointExternalDataService.getPlanId(url);
        return siteUrl && planId && TextService.getPlanDashboardKey(siteUrl, planId);
      },

      getCheckList: async (url: string, plan: IVistoPlan) => {
        return undefined;
      },

      removalWarning: (items: IVistoListItem[]) => '',
      removalAction: (items: IVistoListItem[]) => Promise.resolve(),

      synchronize: async (plan: IVistoPlan, items: IVistoListItemWithProgress[], notify: INotify, operation: Operation, options: IOperationOptions): Promise<IVistoPlan> => {

        clearInfoBar(notify, 'VisPlanLink');

        try {

          if (!plan.editable) {
            return plan;
          }

          const sourceUrls = Object.keys(plan.items)
            .map(itemId => plan.items[itemId] as IVistoListItemWithProgress)
            .filter(item => SharepointExternalDataService.isLinkedItem(item.sourceItemUrl))
            .map(item => item.sourceItemUrl);

          if (!sourceUrls.length)
            return plan;

          const updates: IItemChanges<IVistoListItemWithProgress>[] = [];

          const results = await SharepointExternalDataService.getLinkedVisPlanData(sourceUrls);
          const actions = PlanDataService.getItemsHaving<IVistoListItemWithProgress>(plan.items, item => this.isLinkedItem(item.sourceItemUrl));
          for (const action of actions) {
            const progress = results[action.sourceItemUrl];
            if (progress) {
              const changes = ChangesService.getChanges(action, progress, ['startDate', 'endDate', 'percentComplete', 'plannedPercentComplete', 'comments']);
              if (changes.detected)
                updates.push({ item: action, changes });
            } else {
              notifyInfoBar(notify, notify && {
                type: NotificationType.warn,
                message: TextService.format(strings.SharepointExternalService_UnableToGetProgress, {
                  title: TextService.formatTitle(action, plan.items)
                }),
                error: TextService.format(strings.SharepointExternalService_UnableToGetProgressDetails),
                group: 'VisPlanLink',
                guid: action.guid,
                actions: [
                  this.makeBreakVisPlanLinkNotificationAction(plan, action, notify)
                ]
              });
            }
          }

          trackClient.debug(`Received External data ${updates.length}`, updates);

          if (!updates.length)
            return plan;

          return await StorageService.get(plan.siteUrl).updateItems(plan, updates, notify, { excludeExternals: true });

        } catch (error) {
          notifyInfoBar(notify, {
            type: NotificationType.error,
            group: 'VisPlanLink',
            message: TextService.format(strings.ErrorMessage_UpdatingLinkedProgress),
            error: error
          });
        }
        return plan;
      }
    };
  }

  /**
   * Converts source item URL into logical structure (site, list, id, kind)
   * @param sourceUrl the source item URL
   */

  public static parseSourceUrl(absoluteUrl: string) {

    const url = new URL(absoluteUrl);

    const pathItems = TextService.splitPath(url);
    const spliceCount = pathItems[pathItems.length - 3] === 'Lists' ? 3 : 2; // can be either /Lists/<ListName> or just /<ListName> (2.0)
    const webPath = pathItems.slice(0, pathItems.length - spliceCount).join('/'); // strip list name and page name

    const webUrl = url.protocol + '//' + url.host + webPath;
    const listUrl = pathItems.slice(0, pathItems.length - 1).join('/');
    const itemId = +SharepointExternalDataService.getQueryVariable(url.search, 'ID');
    const listKind = SharepointExternalDataService.getListKind(absoluteUrl);

    return { webUrl, listUrl, itemId, listKind, items: {} };
  }

  public static async getLinkedVisPlanData(sourceUrls: string[]): Promise<{ [key: string]: IVistoListItemWithProgress }> {

    if (!sourceUrls?.length)
      return {};

    const itemSourceSet = sourceUrls
      .reduce((r, sourceUrl: string) => {

        const parsed = SharepointExternalDataService.parseSourceUrl(sourceUrl);

        const key = `${parsed.webUrl}###${parsed.listUrl}`;
        if (!r[key]) {
          r[key] = parsed;
        }
        r[key].items[parsed.itemId] = sourceUrl;
        return r;
      }, {});

    const results = {};

    const queries = Object
      .keys(itemSourceSet)
      .map(key => itemSourceSet[key])
      .map(async (itemSource) => {

        const sp = AuthService.getSpClient(itemSource.webUrl);
        const spList = sp.web.getList(itemSource.listUrl);

        const srcFields = getListDefinition<IVistoListItemWithProgress>(itemSource.listKind).fields;

        const propertyNames: (keyof IVistoListItemWithProgress)[] = ['startDate', 'endDate', 'percentComplete', 'plannedPercentComplete', 'comments', 'itemId', 'name'];
        let query = spList.items.select(...propertyNames.map(fieldName => srcFields[fieldName].name));
        const itemIds = itemSource.items;
        if (Object.keys(itemIds).length < 10) {
          query = query.filter(Object.keys(itemIds).map(itemId => `${srcFields.itemId.name} eq '${itemId}'`).join(' or '));
        }

        const [spItems, timeZone] = await Promise.all([
          query(),
          sp.web.regionalSettings.timeZone()
        ]);

        const updater = new ListItemUpdater(timeZone.Information.Bias + timeZone.Information.DaylightBias);

        for (const spItem of spItems) {

          const result: IVistoListItemWithProgress = { kind: itemSource.kind };

          for (const fieldName of propertyNames) {
            updater.getItemSpFieldUsingFieldName(srcFields[fieldName], result, spItem, fieldName, true);
          }

          const sourceItemUrl = itemIds[result.itemId];
          results[sourceItemUrl] = result;
        }
      });

    await Promise.all(queries);

    return results;
  }

  public static isLinkedItem(url: string) {
    return url && !!SharepointExternalDataService.getPlanId(url);
  }

  public static getSiteUrl(url: string): string {
    const parsed = url && this.parseSourceUrl(url);
    return parsed && parsed.webUrl;
  }

  public static getPlanId(url: string): string {
    if (url) {
      var regex = new RegExp(`/Visto_[^_]+_([^/]+)/`, 'i');
      const matches = regex.exec(url);
      if (matches?.length === 2)
        return matches[1];
    }
  }

  public static getItemId(url: string): number {
    const parsed = url && this.parseSourceUrl(url);
    return parsed && parsed.itemId;
  }

  public static getListKind(url: string): VistoKind {
    if (url) {
      const kinds = PlanDataService.getAllVistoKinds(true);
      for (const kind of kinds) {
        var regex = new RegExp(`/Visto_${VistoKind[kind]}_[^/]+`, 'i');
        if (regex.test(url))
          return kind;
      }
    }
  }

  private static getQueryVariable(search: string, variable: string) {
    if (search && variable) {
      const query = search.substring(1);
      const vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    }
  }
}
