import * as React from 'react';
import { Stack, Text, Spinner } from '@fluentui/react';

import { VistoActionItem, IVistoPlan, VistoDpItem, VistoKind } from 'sp';
import { BasicDialog } from 'dialogs/common';
import { InfoBar, useErrorInfo } from 'components';

import * as strings from 'VistoWebPartStrings';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { ProgressBlock } from 'frames/TopFrame/sidebars/common';
import { makeGuidString } from 'shared/guid';
import { ProjectLinkEditor } from './ProjectLinkEditor';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { ProjectDataService } from '../services';
import { PlanDataService } from 'services/PlanDataService';
import { PlanValidationService } from 'services/PlanValidationService';
import { FocusPicker } from 'dialogs/common/FocusPicker';

export function AddProjectActionDialog(props: {
  plan: IVistoPlan;
  dp: VistoDpItem;
  onDismiss: (changed: boolean) => void;
}) {

  React.useEffect(() => trackClient.page('AddMicrosoftProjectActionDialog'), []);

  const { dispatchCommand, notify, isPlanEditEnabled } = React.useContext(AppContext);

  const [errorInfo, setErrorInfo] = useErrorInfo();
  const [isReadingLinkedProgress, setIsReadingLinkedProgress] = React.useState(false);

  const activeFocus = PlanDataService.getActiveFocus(props.plan);

  const [newAction, setNewAction] = React.useState<VistoActionItem>({
    kind: VistoKind.Action,
    guid: makeGuidString(),
    assignedTo: [],
    dpGuid: props.dp.guid,
    lopGuid: props.dp.lopGuid,
    focusGuid: activeFocus?.guid ?? null,
    useFocusDates: false,
    effectGuid: null,
  });

  React.useEffect(() => {

    const duplicateError = PlanValidationService.validateSourceLink(props.plan, newAction, newAction.sourceItemUrl);
    if (duplicateError) {
      setErrorInfo(duplicateError);
      setIsReadingLinkedProgress(false);
      return;
    }

    setErrorInfo(null);
    if (newAction.sourceItemUrl) {
      setIsReadingLinkedProgress(true);
      const timeout = setTimeout(() => {
        const cache = {};
        ProjectDataService.getItemProgress(newAction, props.plan.statusDate, cache).then(
          progress => {
            setIsReadingLinkedProgress(false);
            setNewAction({ ...newAction, ...progress });
          }, (error: Error) => {
            setIsReadingLinkedProgress(false);
            setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.MSProjectConnection_ErrorGettingProgress), error });
          }
        );
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [newAction.sourceItemUrl]);

  const save = () => {
    return dispatchCommand({
      prepare: async () => {
        return {
          do: async (plan) => {
            return await StorageService.get(plan.siteUrl).createItems(plan, [newAction], notify, { dashboard: true })
          },
          undo: async (plan) => {
            return await StorageService.get(plan.siteUrl).deleteItems(plan, [newAction], notify, { dashboard: true })
          },
        };
      },
      message: TextService.format(strings.ItemEditDialog_CommandCreate, { itemName: TextService.formatTitle(newAction, props.plan.items) }),
      name: CommandName.AddMicrosoftProjectActionDialog
    }, {
      wrap: false
    });
  };

  return (
    <BasicDialog
      isModeless={true}
      onDismiss={props.onDismiss}
      buttonOkAction={save}
      isButtonOkDisabled={!isPlanEditEnabled || isReadingLinkedProgress || !newAction.sourceItemUrl || !!errorInfo && (errorInfo.type === NotificationType.error || errorInfo.type === NotificationType.warn)}
      title={TextService.format(strings.EditMsProjectActionDialog_Title)}
      buttonOkText={TextService.format(strings.ItemEditDialog_ButtonOkCreate)}
      buttonOkBusyText={TextService.format(strings.ItemEditDialog_ButtonOkCreateBusy)}
      buttonCancelText={TextService.format(strings.ButtonCancel)}
    >
      <Stack tokens={{ childrenGap: 's1' }}>

        <ProjectLinkEditor
          plan={props.plan}
          sourceItemUrl={newAction.sourceItemUrl}
          setSourceItemUrl={val => setNewAction({ ...newAction, sourceItemUrl: val })}
        />

        <FocusPicker
          plan={props.plan}
          disabled={!isPlanEditEnabled}
          label={TextService.format(strings.EditActionDialog_FocusLabel)}
          focusGuid={newAction.focusGuid}
          setFocusGuid={val => setNewAction({ ...newAction, focusGuid: val })}
        />
        <InfoBar {...errorInfo} />

        <Stack tokens={{ childrenGap: 's1', padding: 's1' }}>
          {isReadingLinkedProgress
            ? <Stack horizontal tokens={{ childrenGap: 's1' }}><Spinner /><Text>{TextService.format(strings.EditSourceLinkDialog_Reading)}</Text></Stack>
            : <ProgressBlock item={newAction} plan={props.plan} />
          }
        </Stack>

      </Stack>
    </BasicDialog>);
}
