import React from 'react';
import { useErrorInfo } from 'components';
import { AuthService, IAuthScopeProps } from 'services/AuthService';
import { IEnvContext, EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { setIsLoading } from 'services/LoadingIndicator';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import strings from 'VistoWebPartStrings';
import { isConsentError, stringifyError } from 'shared/parse';
import { ThemeProvider } from '@fluentui/react';
import { trackClient } from 'shared/clientTelemetry';
import { TokenKind } from 'shared/TokenKind';
import { PlannerConfigurationService } from 'integrations/planner/services';
import { ApiService } from 'services/api/ApiService';
import { SharepointService } from 'services/SharepointService';
import { LocalStorageService } from 'services/LocalStorageService';
import { UrlService } from 'shared/urlService';
import { AppTheme } from 'components/AppTheme';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { PlaceholderConsent } from 'components/PlaceholderConsent';
import { PlaceholderAuthError } from 'components/PlaceholderAuthError';
import { ErrorBoundary } from 'react-error-boundary';
import { PlaceholderCrash } from 'components/PlaceholderCrash';

export function TeamsAuthScope(props: IAuthScopeProps) {

  const [isProcessing, setIsProcessing] = React.useState(false);
  const [consentError, setConsentError] = useErrorInfo();
  const [authError, setAuthError] = useErrorInfo();

  const [envContext, setEnvContext] = React.useState<IEnvContext>(null);

  const getEnvContext = async (teamsContext: microsoftTeams.app.Context, name: string, kind: TokenKind): Promise<IEnvContext> => {

    AuthService.TokenProvider = (tokenKind, host) => AuthService.getServerSideToken(() => TeamsAuthService.getTeamsToken(), tokenKind, host);
    AuthService.AuthScope = TeamsAuthScope;
    AuthService.getConsent = TeamsAuthService.getConsent;

    let siteUrl = kind === TokenKind.sharepoint ? teamsContext.sharePointSite.teamSiteUrl : '';

    // Workaround for iOS client bug
    // https://github.com/OfficeDev/microsoft-teams-library-js/issues/2266
    if (siteUrl.endsWith('.aspx')) {
      const origin = UrlService.getOrigin(siteUrl);
      const teamSite = teamsContext.channel?.relativeUrl?.split('/').slice(0, 3).join('/');
      siteUrl = `${origin}${teamSite}`
    }

    AuthService.configure({
      defaultBaseUrl: siteUrl,
      tid: teamsContext.user.tenant?.id,
      userObjectId: teamsContext.user?.id,
      userTeamRole: teamsContext.team?.userRole,
    });

    trackClient.origin = UrlService.getOrigin(siteUrl);
    trackClient.initialize(name);

    ApiService.configure(() => TeamsAuthService.getTeamsToken());
    StorageService.configure({
      sharepointService: new SharepointService(),
      indexeddbService: new LocalStorageService()
    });

    PlannerConfigurationService.configure(teamsContext.user.tenant.id, teamsContext.team?.groupId);

    const webPartContext = await SharepointService.makeWebPartContext(siteUrl, teamsContext.team?.displayName, teamsContext.app?.locale);

    const teamsToken = await TeamsAuthService.getTeamsToken();
    const payload = teamsToken && jwtDecode<JwtPayload>(teamsToken);
    const userDisplayName = payload ? payload['name'] : TextService.format(strings.Context_Local);

    const ctx: IEnvContext = {
      tid: teamsContext.user?.tenant?.id,
      groupId: teamsContext.team?.groupId,
      userPrincipalName: teamsContext.user?.userPrincipalName,
      userObjectId: teamsContext.user?.id,
      userDisplayName,

      hostKind: teamsContext.app?.host?.clientType === 'ios' || teamsContext.app?.host?.clientType === 'android'
        ? 'TeamsMobile'
        : teamsContext.app.host?.clientType === 'desktop'
          ? 'TeamsDesktop'
          : 'TeamsWeb',

      entityId: teamsContext.page?.id,
      teamId: teamsContext.team?.internalId,
      teamName: teamsContext.team?.displayName,
      channelId: teamsContext.channel?.id,
      channelName: teamsContext.channel?.displayName,
      siteUrl: siteUrl,

      defaultFolderName: teamsContext.channel?.displayName,
      defaultFolderRelativeUrl: teamsContext.channel?.relativeUrl,
      webPartContext,
      subEntityId: teamsContext.page?.subPageId,
    };

    // missing channelRelativeUrl in popout
    // see https://github.com/MicrosoftDocs/msteams-docs/issues/5686
    if (siteUrl && !ctx.defaultFolderRelativeUrl) {
      const webProperties = await SharepointService.getWebProperties(siteUrl);
      const serverRelativeUrl = UrlService.getPathName(siteUrl);
      ctx.defaultFolderRelativeUrl = `${serverRelativeUrl}/${webProperties?.GroupDocumentsUrl}/${ctx.channelName}`;
    }

    return ctx;
  };

  const authenticate = async () => {
    setIsLoading(TextService.format(strings.LoadingIndicator_Authenticating));
    setIsProcessing(true);

    const teamsContext = await microsoftTeams.app.getContext();

    // workaround for teams bug on android
    // https://github.com/OfficeDev/microsoft-teams-library-js/issues/1438
    if (teamsContext.app?.host?.clientType === microsoftTeams.HostClientType.android) {
      if (teamsContext.channel?.relativeUrl && teamsContext.sharePointSite?.teamSiteUrl) {
        const teamSuteUrl = new URL(teamsContext.sharePointSite.teamSiteUrl);
        const pathItems = teamsContext.channel.relativeUrl.split('/');
        teamsContext.sharePointSite.teamSitePath = `/${pathItems[1]}/${pathItems[2]}`
        teamsContext.sharePointSite.teamSiteUrl = `${teamSuteUrl.origin}${teamsContext.sharePointSite.teamSitePath}`;
      }
    }

    // some glitch in Microsoft API - force consent 03.07.24
    if (!teamsContext.sharePointSite.teamSiteUrl && teamsContext.team?.internalId) {
      await AuthService.getConsent(TokenKind.dashboard, '', authenticate);
    }
    setTheme(AppTheme.loadAppTheme(teamsContext.app.theme));

    const ctx = await getEnvContext(teamsContext, props.name, props.kind);

    await props.onLoad(ctx);

    setEnvContext(ctx);
  };

  const consent = async () => {
    try {
      await AuthService.getConsent(props.kind, '', authenticate);
      setConsentError(null);
    } catch (error) {
      setConsentError({ type: NotificationType.warn, message: TextService.format(strings.AuthService_ErrorGetConsent, { reason: stringifyError(error) }) });
    } finally {
      setIsLoading('');
      setIsProcessing(false);
    }
  };

  const init = async () => {

    try {
      await microsoftTeams.app.initialize();
      await authenticate();
      microsoftTeams.app.registerOnThemeChangeHandler(onThemeChange);
      microsoftTeams.app.notifySuccess();
    } catch (error) {
      if (isConsentError(error)) {
        AuthService.resetAuth(props.kind);
        setConsentError(error);
      } else {
        microsoftTeams.app.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          message: TextService.format(strings.AuthError_Connect, { error: stringifyError(error) })
        });
        setAuthError({ type: NotificationType.error, message: TextService.format(strings.AuthError_Connect), error });
      }
    } finally {
      setIsLoading('');
      setIsProcessing(false);
    }
  };

  const refresh = async () => {
    AuthService.resetAuth(props.kind);
    window.location.reload();
  };

  React.useEffect(() => {
    init();
  }, []);

  const [theme, setTheme] = React.useState(() => AppTheme.loadAppTheme());

  const onThemeChange = (themeName: string) => {
    const result = AppTheme.loadAppTheme(themeName);
    setTheme(result);
  };

  const bodyStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundColor: 'transparent',
  };

  return (
    <ThemeProvider theme={theme} style={bodyStyle}>
      <ErrorBoundary FallbackComponent={PlaceholderCrash}>
        {consentError
          ? <PlaceholderConsent consentError={consentError} disabled={isProcessing} onConfigure={consent} />
          : authError
            ? <PlaceholderAuthError authError disabled={isProcessing} onConfigure={refresh} />
            : envContext && <EnvContext.Provider value={envContext}>{props.children}</EnvContext.Provider>
        }
      </ErrorBoundary>
    </ThemeProvider>
  );
}
