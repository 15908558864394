import * as React from 'react';
import { DirectionalHint, IButtonStyles, IContextualMenuItem, IIconProps, IMessageBarStyles, mergeStyles, MessageBar, MessageBarButton, MessageBarType, Stack, TeachingBubble, useTheme } from '@fluentui/react';
import { INotificationAction, NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { NotificationActionButton } from './NotificationActionButton';
import strings from 'VistoWebPartStrings';
import { Notifications } from 'frames/TopFrame/sidebars/Notifications';
import { INotifications } from 'frames/hooks/NotificationsHook';
import { trackClient } from 'shared/clientTelemetry';
import { CommandName } from 'shared/CommandName';
import { translateInfoBarType } from './translateInfoBarType';
import { Commands } from 'services/Commands';
import { AppContext } from 'services/AppContext';

const buttonId = 'button_1e60ec7cfc6b450681be30649b688666';

export const NotificationBar = (props: {
  notifications: INotifications;
  top: number;
}) => {

  const theme = useTheme();
  const status = props.notifications.status || { type: NotificationType.log, message: '' };
  const appContext = React.useContext(AppContext);

  const notificationButtonStyles: IButtonStyles = {
    root: {
      fontSize: 11,
      height: 22,
      marginLeft: 8,
      padding: '0 4px',
      minHeight: 22
    }
  };

  const onBubble = () => {
    window.open('https://visplan.com/book-a-demo', '_blank');
  }

  const linkStyleClassName = mergeStyles({
    'a': {
      color: theme.palette.white
    }
  });

  const [isNotificationSidebarOpen, setIsNotificationSidebarOpen] = React.useState(false);

  const notificationMenuItems: IContextualMenuItem[] =
    [
      {
        key: 'NotificationsClear',
        text: TextService.format(strings.MenuItem_ClearNotifications),
        disabled: props.notifications.items.length === 0,
        iconProps: { iconName: 'Clear' },
        onClick: () => {
          props.notifications.clearNotifications();
          setIsNotificationSidebarOpen(false);
        }
      }
    ];

  const hideBubble = () => {
    trackClient.event(CommandName.HideLicenseBubble);
    props.notifications.clearNotifications('bubble');
  }

  const messageBarType = translateInfoBarType(status.type);

  const actions: INotificationAction[] = [];
  if (status.actions) {
    actions.push(...status.actions);
  }
  if (status.guid) {
    actions.push({
      title: TextService.format(strings.InfoBar_SelectItemButton),
      action: async () => {
        Commands.setSelection(appContext, status.guid);
      }
    });
  }
  if (status.error || status.type === NotificationType.error || status.type === NotificationType.warn || actions.length > 0) {
    actions.push({
      title: TextService.format(strings.NotificationButton_ShowDetails),
      action: async () => {
        setIsNotificationSidebarOpen(true);
      }
    });
  }

  const containerStyle: React.CSSProperties = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: props.top,
    boxShadow: theme.effects.elevation8,
    zIndex: 900
  };

  return status.message &&
    <Stack style={containerStyle} >
      <MessageBar messageBarType={messageBarType} onDismiss={() => props.notifications.clearNotifications()}>
        <span dangerouslySetInnerHTML={{ __html: status.message }}></span>
        {actions.map((action, index) => <NotificationActionButton styles={notificationButtonStyles} key={index} action={action} />)}
        <Notifications
          items={props.notifications.items}
          menuItems={notificationMenuItems}
          isOpen={isNotificationSidebarOpen}
          onDismiss={() => setIsNotificationSidebarOpen(false)}
        />
        {!!status.bubble && <TeachingBubble
          calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
          target={`#${buttonId}`}
          primaryButtonProps={{ children: TextService.format(strings.CoachmarkButton_ContactUs), onClick: onBubble }}
          secondaryButtonProps={{ children: TextService.format(strings.CoachmarkButton_Later), onClick: hideBubble }}
          headline={strings.AppFrame_Title} ><span className={linkStyleClassName} dangerouslySetInnerHTML={{ __html: status.bubble }} ></span></TeachingBubble>}
      </MessageBar>
    </Stack>
};
