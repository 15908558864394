import { Stack, TextField, DefaultButton, Link, Text, useTheme } from '@fluentui/react'
import { InfoBar, useErrorInfo } from 'components'
import React from 'react'
import { ChangesService } from 'services/ChangesService'
import { EnvContext } from 'services/EnvContext'
import { ExcelService } from 'services/ExcelService'
import { NotificationType } from 'services/Notify'
import { StorageCacheService } from 'services/StorageCacheService'
import { TextService } from 'services/TextService'
import { isConsentError } from 'shared/parse'
import { UrlService } from 'shared/urlService'
import { IVistoPlan, KeyResultValueKind, VistoKeyResultItem, VistoKeyResultValueItem } from 'sp'
import strings from 'VistoWebPartStrings'

export const EditKeyResultExcel = (props: {
  plan: IVistoPlan;

  valueKind: KeyResultValueKind;
  kr: VistoKeyResultItem;

  values: VistoKeyResultValueItem[];
  onSetValues: (values: VistoKeyResultValueItem[]) => void;

  excelOptions: IkeyResultExcelOptions;
  setExcelOptions: (val: IkeyResultExcelOptions) => void;
}) => {

  const { defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const setDefaultExcelOptions = async () => {
    const action = async () => {
      const getDefaultExcelOptions = await ExcelService.getDefaultExcelOptions(props.plan, props.kr, defaultFolderRelativeUrl, props.valueKind);
      props.setExcelOptions(getDefaultExcelOptions);
    }
    try {
      setIsLoading(true);
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, {
          addNotification: (n) => setErrorInfo(n),
          clearNotifications: () => setErrorInfo(null)
        })
      } else {
        setErrorInfo({
          type: NotificationType.error,
          message: TextService.format(strings.ExcelError_CannotGetDefaultOptions),
          error
        });
      }
    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    const excelOptions = props.excelOptions;
    if (!excelOptions.excelWorkbook && !excelOptions.excelTable && !excelOptions.excelDateColumn && !excelOptions.excelValueColumn && !excelOptions.excelCommentColumn) {
      setDefaultExcelOptions();
    }
  }, [props.plan, props.kr, defaultFolderRelativeUrl, props.valueKind]);

  const createKeyResultWorkbook = async () => {

    const action = async () => {
      setIsLoading(true);
      setErrorInfo(null);
      StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/drive');
      const options = await ExcelService.createKeyResultData(
        props.plan,
        props.kr,
        props.excelOptions,
        props.values);

      props.setExcelOptions(options);
    }

    try {
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, {
          addNotification: (n) => setErrorInfo(n),
          clearNotifications: () => setErrorInfo(null)
        })
      } else {
        setErrorInfo({
          type: NotificationType.error,
          message: TextService.format(strings.ExcelService_CreateError),
          error
        });
      }

    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  const refreshKeyResultValues = async () => {

    const notify = {
      addNotification: (n) => setErrorInfo(n),
      clearNotifications: () => setErrorInfo(null)
    };

    const action = async () => {
      setErrorInfo(null);
      setIsLoading(true);
      StorageCacheService.resetCache('https://graph.microsoft.com/v1.0/drive');
      const krvs = await ExcelService.getKeyResultData(props.plan, props.kr, props.excelOptions, props.valueKind, notify);
      if (ExcelService.changesDetected(props.values, krvs)) {
        props.onSetValues(krvs);
      }
    }

    try {
      await action();
    } catch (error) {
      if (isConsentError(error)) {
        ExcelService.makeConsentNotification(action, notify)
      } else {
        setErrorInfo({
          type: NotificationType.error,
          message: TextService.format(strings.ExcelService_RefreshError),
          error
        });
      }
    } finally {
      updateWorkbookUrl();
      setIsLoading(false);
    }
  }

  const [isLoading, setIsLoading] = React.useState(false);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const [isCheckingWorkbookStatus, setCheckingWorkbookStatus] = React.useState(false);
  const [workbookUrl, setWorkbookUrl] = React.useState<string>(undefined);
  const [tableExists, setTableExists] = React.useState<boolean>(false);

  const [firstTimeUpdate, setFirstTimeUpdate] = React.useState(true);
  const ensureExcelOptionsOnFirstRun = async (file: any) => {

    const excelOptions = { ...props.excelOptions };

    const path: string = file.parentReference?.path || '';
    const startOfPath = path ? path.indexOf('/root:/') : -1;
    const folder = startOfPath > -1 ? path.substring(startOfPath + '/root:/'.length) : '';
    const excelWorkbook = UrlService.combine(folder, file.name);
    excelOptions.excelWorkbook = excelWorkbook;

    const sourcedoc = new URLSearchParams(new URL(file.webUrl).search).get('sourcedoc');
    const excelSourceDoc = sourcedoc && sourcedoc.replace(/^{|}$/g, '');
    if (excelSourceDoc && props.excelOptions.excelSourceDoc !== excelSourceDoc) {
      excelOptions.excelSourceDoc = excelSourceDoc;
    }

    const changes = ChangesService.getChanges(props.excelOptions, excelOptions);
    if (changes.detected) {
      props.setExcelOptions(excelOptions);
    }
  }

  const updateWorkbookUrl = async () => {
    if (props.excelOptions.excelWorkbook && props.excelOptions.excelTable) {
      const action = async () => {
        setCheckingWorkbookStatus(true);
        const info = await ExcelService.getWorkbookInfo(props.plan.siteUrl, props.excelOptions);
        if (info) {
          const { file, tables } = info;
          setWorkbookUrl(file.webUrl);

          const tableExists = tables.some(t => t.name === props.excelOptions.excelTable);
          setTableExists(tableExists);

          if (file && (firstTimeUpdate || !props.excelOptions.excelSourceDoc)) {
            ensureExcelOptionsOnFirstRun(file);
            setFirstTimeUpdate(false);
          }
        }
      }
      try {
        await action();
        setCheckingWorkbookStatus(false);
      } catch (error) {
        if (isConsentError(error)) {
          ExcelService.makeConsentNotification(action, {
            addNotification: (n) => setErrorInfo(n),
            clearNotifications: () => setErrorInfo(null)
          })
        } else {
          if (error.status !== 404) {
            setErrorInfo({
              type: NotificationType.error,
              message: TextService.format(strings.ExcelService_RefreshError),
              error
            });
          }
        }
      }
    }
  }

  React.useEffect(() => {
    setCheckingWorkbookStatus(true);
    setWorkbookUrl(undefined);
    setTableExists(false);
    const timeout = setTimeout(updateWorkbookUrl, 500);
    return () => {
      setCheckingWorkbookStatus(false);
      clearTimeout(timeout);
    }
  }, [props.excelOptions.excelWorkbook, props.excelOptions.excelTable, props.excelOptions.excelSourceDoc]);

  const theme = useTheme();

  return (
    <Stack tokens={{ childrenGap: 's1' }}>
      <Stack style={{ maxWidth: 600 }}><InfoBar {...errorInfo} /></Stack>
      <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 'm' }}>
        <Stack grow>
          <TextField
            label={TextService.format(strings.ExcelBlockLabel_Workbook)}
            disabled={isLoading}
            value={props.excelOptions.excelWorkbook}
            onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelWorkbook: val, excelSourceDoc: undefined })}
          />
          <Link href={workbookUrl} disabled={isCheckingWorkbookStatus || !workbookUrl} target='_blank'>
            {isLoading
              ? <Text>{TextService.format(strings.ExcelBlockStatus_Processing)}</Text>
              : isCheckingWorkbookStatus
                ? <Text>{TextService.format(strings.ExcelBlockStatus_CheckingFileStatus)}</Text>
                : workbookUrl
                  ? <Text>{TextService.format(strings.ExcelBlockStatus_OpenExcelWorkbook)}</Text>
                  : <Text style={{ color: theme.palette.orange }}>{TextService.format(strings.ExcelBlockStatus_NoWorkbook)}</Text>
            }
          </Link>
        </Stack>
        <Stack>
          <TextField
            label={TextService.format(strings.ExcelBlockLabel_Table)}
            disabled={isLoading}
            value={props.excelOptions.excelTable}
            onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelTable: val })}
          />
          <Link disabled>
            {workbookUrl
              ? tableExists 
                ? <Text>{TextService.format(strings.ExcelBlockStatus_TableFound)}</Text>
                : <Text style={{ color: theme.palette.orange }} >{TextService.format(strings.ExcelBlockStatus_NoTable)}</Text>
              : '\u00A0'
            }
          </Link>
        </Stack>
        <Stack>
          {(workbookUrl && tableExists)
            ? <DefaultButton
              text={TextService.format(strings.ExcelBlockLabel_ButtonRefresh)}
              onClick={refreshKeyResultValues}
              disabled={isLoading || isCheckingWorkbookStatus}
            />
            : <DefaultButton
              text={TextService.format(strings.ExcelBlockLabel_ButtonCreate)}
              onClick={createKeyResultWorkbook}
              disabled={isLoading || isCheckingWorkbookStatus}
            />
          }
          <Link disabled>{'\u00A0'}</Link>
        </Stack>
      </Stack>
      <Stack horizontal verticalAlign='end' horizontalAlign='start' tokens={{ childrenGap: 'm' }}>
        <TextField
          label={TextService.format(strings.ExcelBlockLabel_DateColumn)}
          disabled={isLoading}
          value={props.excelOptions.excelDateColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelDateColumn: val })}
        />
        <TextField
          label={TextService.format(strings.ExcelBlockLabel_ValueColumn)}
          disabled={isLoading}
          value={props.excelOptions.excelValueColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelValueColumn: val })}
        />
        <TextField
          label={strings.ExcelBlockLabel_CommentColumn}
          disabled={isLoading}
          value={props.excelOptions.excelCommentColumn}
          onChange={(_, val) => props.setExcelOptions({ ...props.excelOptions, excelCommentColumn: val })}
        />
      </Stack>
    </Stack>

  )
}
