import { Stack } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import React from 'react';
import { StorageCacheService } from 'services/StorageCacheService';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { InfoBar } from './InfoBar';
import { INotification, NotificationType } from 'services/Notify';

export const PlaceholderCrash = ({ error }) => {

  const refresh = async () => {
    StorageCacheService.resetCache();
    window.location.reload();
  }

  const notification: INotification = error && {
    message: TextService.format(strings.CrashNotification_Message),
    type: NotificationType.error,
    error: error
  }

  return (
    <Stack>
      {notification && <InfoBar {...notification} />}
      <Placeholder
        iconName='Thunderstorms'
        iconText={TextService.format(strings.CrashPlaceholder_IconText)}
        description={TextService.format(strings.CrashPlaceholder_Description)}
        buttonLabel={TextService.format(strings.CrashPlaceholder_ButtonLabel)}
        onConfigure={refresh}
      />
    </Stack>
  );
}
