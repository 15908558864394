import * as React from 'react';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, DialogType, IDialogContentProps, IModalProps, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as strings from 'VistoWebPartStrings';
import { InfoBar, useErrorInfo } from 'components';
import { defaultModalProps } from './DefaultModalProps';
import { EnvContext } from 'services/EnvContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';

export function BasicDialog(props: {
  title?: string;
  subText?: string;
  minWidth?: string | number;
  maxWidth?: string | number;
  isModeless?: boolean;
  children?: React.ReactNode;
  onDismiss: (changed: boolean) => void;
  onValidate?: () => boolean;

  buttonOkText: string;
  buttonOkBusyText: string;
  buttonOkAction: () => Promise<any>;
  isButtonOkDisabled?: boolean;

  buttonCancelText: string;

  statusBarItem?: JSX.Element;
  zIndex?: number;
  autoFocus?: boolean;
}) {

  const autoFocus = typeof props.autoFocus === 'boolean' ? props.autoFocus : true;

  const { hostKind } = React.useContext(EnvContext);

  const contentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    subText: props.subText
  };

  const isMobile = (hostKind === 'WebMobile' || hostKind === 'TeamsMobile');

  const modalProps: IModalProps = {
    ...defaultModalProps,
    layerProps: {
      styles: {
        root: {
          position: 'fixed',
          zIndex: props.zIndex ?? 10000
        }
      }
    },
    isModeless: props.isModeless,
    styles: !isMobile && props.isModeless && { root: { width: '75%' } } // sidebar offset
  };

  const [isBusy, setIsBusy] = React.useState(false);
  const [errorInfo, setErrorInfo] = useErrorInfo();

  const action = () => {
    if (props.onValidate && !props.onValidate()) {
      return;
    }
    setIsBusy(true);
    setErrorInfo(null);
    props.buttonOkAction().then(() => {
      // setIsBusy(false);
      props.onDismiss(true);
    }, (error: Error) => {
      setIsBusy(false);
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.BasicDialog_ActionError), error });
    });
  };

  const minWidth = props.minWidth ?? 600;
  const maxWidth = props.maxWidth ?? (isMobile ? '100vw' : '50vw');

  return (
    <Dialog minWidth={minWidth} maxWidth={maxWidth} hidden={false} onDismiss={() => props.onDismiss(false)} dialogContentProps={contentProps} modalProps={modalProps}>
      <InfoBar {...errorInfo} />
      {props.children}
      <DialogFooter>
        {props.statusBarItem && <div style={{ float: 'left'}}>{props.statusBarItem}</div>}
        <PrimaryButton autoFocus={autoFocus} onClick={action} disabled={props.isButtonOkDisabled || isBusy} >
          <Stack horizontal tokens={{ childrenGap: 'm' }}>
            {isBusy && <Spinner size={SpinnerSize.small} />}
            <span>{isBusy ? props.buttonOkBusyText : props.buttonOkText}</span>
          </Stack>
        </PrimaryButton>
        <DefaultButton onClick={() => props.onDismiss(false)} text={props.buttonCancelText} />
      </DialogFooter>
    </Dialog>
  );
}
