import React from 'react';
import { Toggle, TooltipHost } from '@fluentui/react';
import { EnvContext } from 'services/EnvContext';

export const ToolbarToggle = (props: { label: string, tooltip: string, value: boolean, onChange: (value: boolean) => void }) => {

  return (
    <TooltipHost content={props.tooltip}>
      <Toggle inlineLabel styles={{ label: { wordBreak: 'normal', whiteSpace: 'nowrap' } }} label={props.label} checked={props.value} onChange={(_, val) => props.onChange(!props.value)} />
    </TooltipHost>
  );
}
