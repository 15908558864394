import * as React from 'react';
import { AppContext } from 'services/AppContext';
import { NotificationType } from 'services/Notify';
import { TextService } from 'services/TextService';
import { Stack, TagPicker, IBasePickerSuggestionsProps, ITag, Label, ComboBox } from '@fluentui/react';
import { DevOpsDataService, DevOpsService } from '../services';
import { IVistoPlan } from 'sp';
import { InfoBar, useErrorInfo } from 'components/InfoBar';
import * as strings from 'VistoWebPartStrings';
import { DevOpsItemTypeCombobox } from './DevOpsItemTypeCombobox';

interface IDevOpsItem extends ITag {
  icon: string;
  color: string;
}

export function DevOpsLinkEditor(props: {
  plan: IVistoPlan;
  sourceItemUrl: string;
  setSourceItemUrl: (sourceItemUrl: string) => void;
}) {

  const { propertyBag, setPropertyBag } = React.useContext(AppContext);

  const devopsUrl = DevOpsService.getPlanDevOpsUrl(props.plan);

  const [errorInfo, setErrorInfo] = useErrorInfo();

  const [projects, setDevOpsProjects] = React.useState<string[]>([]);

  const defaultProject = DevOpsService.getProjectName(props.sourceItemUrl) ?? propertyBag?.integrations?.devops?.defaultProject;
  const [selectedProject, _setSelectedProject] = React.useState(defaultProject);
  const setSelectedProject = (name: string) => {
    setPropertyBag({ integrations: { ...propertyBag?.integrations, devops: { ...propertyBag?.integrations?.devops, defaultProject: name } } });
    _setSelectedProject(name);
    if (selectedItems.length) {
      const item = selectedItems[0];
      const url = `${devopsUrl}/${encodeURIComponent(name)}/_workitems/edit/${item.key}?icon=${encodeURIComponent(item.icon)}&color=${encodeURI(item.color)}`;
      props.setSourceItemUrl(url);
    }
  };

  const defaultItemId = DevOpsService.getWorkItemIdFromSourceUrl(props.sourceItemUrl);
  const defaultIcon = DevOpsService.getWorkItemIconFomSourceUrl(props.sourceItemUrl);
  const defaultColor = DevOpsService.getWorkItemColorFomSourceUrl(props.sourceItemUrl);
  const [selectedItems, setSelectedItems] = React.useState<IDevOpsItem[]>(defaultItemId ? [{ key: defaultItemId, name: defaultItemId, icon: defaultIcon, color: defaultColor }] : []);

  React.useEffect(() => {

    setErrorInfo(null);

    DevOpsService.getProjects(devopsUrl).then(results => {
      setDevOpsProjects(results.map(d => d.name));
    }, error => {
      setErrorInfo({ type: NotificationType.error, message: TextService.format(strings.MSDevOpsConnection_ErrorGettingDevOpsList), error });
    });

    DevOpsDataService.getWorkItemProgressData(props.plan, props.sourceItemUrl).then(result => {
      if (result) {
        const key = DevOpsService.getWorkItemIdFromSourceUrl(props.sourceItemUrl);
        const icon = DevOpsService.getWorkItemIconFomSourceUrl(props.sourceItemUrl);
        const color = DevOpsService.getWorkItemColorFomSourceUrl(props.sourceItemUrl);
        setSelectedItems([{ key, name: result.name, icon: icon, color: color }]);
      }
    });

  }, [devopsUrl]);

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: TextService.format(strings.DevOpsLinkEditor_ExistingItems),
    noResultsFoundText: TextService.format(strings.DevOpsLinkEditor_NoResultsFound),
  };

  const onResolveSuggestions = async (filter: string, prev: ITag[] | undefined): Promise<IDevOpsItem[]> => {
    const items = await DevOpsService.queryWorkItemBasicData(devopsUrl, selectedProject, filter, itemTypeNames);
    return items.map(item => {
      const itemType = DevOpsService.getPlanItemType(props.plan, item.type, selectedProject);
      return {
        key: item.id,
        name: `#${item.id} ${item.type} '${item.title}'`,
        icon: itemType?.icon,
        color: itemType?.color,
      };
    }, []);
  };

  const getTextFromItem = (item: IDevOpsItem) => `#${item.key} ${item.name}`;

  const onDevOpsTaskChanged = (items: IDevOpsItem[]) => {
    if (items.length) {
      const item = items[0];
      const url = `${devopsUrl}/${encodeURIComponent(selectedProject)}/_workitems/edit/${item.key}?icon=${encodeURIComponent(item.icon)}&color=${encodeURI(item.color)}`;
      props.setSourceItemUrl(url);
    } else {
      props.setSourceItemUrl('');
    }
    setSelectedItems(items);
  };

  const defaultItemTypeNames = DevOpsService.getPlanItemTypeNames(props.plan);
  const itemTypes = defaultItemTypeNames.map(x => DevOpsService.getPlanItemType(props.plan, x, selectedProject));

  const [itemTypeNames, setItemTypeNames] = React.useState(defaultItemTypeNames);

  return (
    <Stack tokens={{ childrenGap: 's1' }}>
      <InfoBar {...errorInfo} />
      <Stack horizontal tokens={{ childrenGap: 'm' }}>
        <ComboBox
          label={TextService.format(strings.DevOpsLinkEditor_ProjectLabel)}
          calloutProps={{ calloutMaxHeight: 200 }}
          options={projects.map(p => ({ key: p, text: p }))}
          selectedKey={selectedProject}
          onChange={(_, val) => setSelectedProject(val.key as string)}
        />
        <DevOpsItemTypeCombobox
          devopsUrl={devopsUrl}
          label={TextService.format(strings.DevOpsLinkEditor_ItemTypeLabel)}
          itemTypes={itemTypes}
          itemTypeNames={itemTypeNames}
          setItemTypeNames={setItemTypeNames}
        />
      </Stack>
      <Label>{TextService.format(strings.DevOpsLinkEditor_PickerLabel)}</Label>
      <TagPicker
        itemLimit={1}
        onEmptyResolveSuggestions={sel => onResolveSuggestions('', sel)}
        onResolveSuggestions={(filter, sel) => onResolveSuggestions(filter, sel)}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        selectedItems={selectedItems}
        onChange={onDevOpsTaskChanged}
        inputProps={{ 
          placeholder: selectedItems.length ? '' : TextService.format(strings.DevOpsLinkEditor_PickerPlaceholder),
        }}
      />
    </Stack>
  );

}
