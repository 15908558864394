import { Link, Stack, Text, useTheme } from '@fluentui/react'
import React from 'react'
import strings from 'VistoWebPartStrings'
import { PlanDataService } from 'services/PlanDataService'
import { TextService } from 'services/TextService'
import { ISelectedCellInfo } from 'shared/ISelectedCellInfo'
import { IVistoPlan, VistoKind } from 'sp'

export const CommentTargetLabel = (props: {
  plan: IVistoPlan;
  selection: ISelectedCellInfo;
  onClick?: () => void;
}) => {

  const { plan, selection } = props;

  const theme = useTheme();

  const item = PlanDataService.getItemByGuid(plan.items, selection?.guid);
  const target = item ? TextService.formatTitle(item, plan.items) : TextService.getVistoKindName(VistoKind.Plan);

  const linkStyle: React.CSSProperties = {
    fontSize: theme.fonts.small.fontSize,
  }

  return (
    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
      <Text variant='small' style={{ color: theme.palette.neutralSecondary }}>{TextService.format(strings.CommentOnTarget)}</Text>
      <Link disabled={!props.onClick} onClick={props.onClick} style={linkStyle}>{target}</Link>
    </Stack>
  )
}
