import * as React from 'react';
import { Stack, Dropdown } from '@fluentui/react';

import { IVistoPlan, VistoActionItem, VistoDpItem, VistoLopItem } from 'sp';
import { BasicDialog } from './common';

import * as strings from 'VistoWebPartStrings';
import { trackClient } from 'shared/clientTelemetry';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { TextService } from 'services/TextService';
import { LicenseService } from 'services/LicenseService';
import { ChangesService } from 'services/ChangesService';
import { LopPicker } from './common/LopPicker';
import { DpPicker } from './common/DpPicker';
import { NULL_KEY } from 'shared/parse';
import { PlannerLinkService } from 'integrations/planner';

export function MoveActionToLopDpDialog(props: {
  plan: IVistoPlan,
  action: VistoActionItem,
  onDismiss: (changed: boolean) => void
}) {

  React.useEffect(() => trackClient.page('MoveActionToLopDpDialog'));

  const { dispatchCommand, notify } = React.useContext(AppContext);
  const [lopGuid, _setLopGuid] = React.useState<string>(props.action.lopGuid);
  const [dpGuid, _setDpGuid] = React.useState<string>(props.action.dpGuid);
  const [effectGuid, setEffectGuid] = React.useState<string>(props.action.effectGuid);

  const effectList = PlanDataService.getDpEffects(props.plan, dpGuid)
    .map(effect => ({ key: effect.guid, text: effect.name }));

  effectList.unshift({
    key: NULL_KEY,
    text: TextService.format(strings.UnassignedItem)
  });

  const setLopGuid = (guid: string) => {
    _setLopGuid(guid);
    setDpGuid(null);
  }

  const setDpGuid = (guid: string) => {
    _setDpGuid(guid);
    setEffectGuid(null);
  }

  const update = () => {
    const update = { dpGuid, lopGuid, effectGuid };
    const command = Commands.makeUpdateCommand([{ item: props.action, changes: ChangesService.getChanges(props.action, update) }], notify);
    return dispatchCommand(command, { wrap: false });
  };

  const getAdditionalDpInfo = (item: VistoDpItem) => {
    if (item.guid === props.action.dpGuid) return;
    const dpAction = PlanDataService.getItemByGuid<VistoDpItem>(props.plan.items, props.action.dpGuid);
    if (!PlannerLinkService.isPlannerLink(dpAction.sourceItemUrl)) return ;
    const disabled = !PlannerLinkService.isPlannerLink(item.sourceItemUrl);
    return { 
      disabled, 
      why: disabled 
        ? TextService.format(strings.MoveActionToLopDpDialog_DpNotLinkedToPlanner)
        : undefined
    };
  }

  const getAdditionalLopInfo = (item: VistoLopItem) => {
    if (item.guid === props.action.lopGuid) return;
    const lopAction = PlanDataService.getItemByGuid<VistoLopItem>(props.plan.items, props.action.lopGuid);
    if (!PlannerLinkService.isPlannerLink(lopAction.sourceItemUrl)) return ;
    const disabled = !PlannerLinkService.isPlannerLink(item.sourceItemUrl);
    return { 
      disabled, 
      why: disabled 
        ? TextService.format(strings.MoveActionToLopDpDialog_LopNotLinkedToPlanner)
        : undefined
    };
  }

  return (
    <BasicDialog
      onDismiss={props.onDismiss}
      buttonOkAction={update}
      isButtonOkDisabled={!lopGuid || !dpGuid}
      title={TextService.format(strings.MoveActionToDp_Message)}
      buttonOkText={TextService.format(strings.MoveActionToDp_ButtonOk)}
      buttonOkBusyText={TextService.format(strings.MoveActionToDp_ButtonProgress)}
      buttonCancelText={TextService.format(strings.MoveActionToDp_ButtonCancel)}
    >
      <Stack>
        <LopPicker
          plan={props.plan}
          label={TextService.format(strings.MoveActionToDp_SelectLOP)}
          lopGuid={lopGuid}
          getAdditionalItemInfo={getAdditionalLopInfo}
          setLopGuid={setLopGuid}
        />
        <DpPicker
          plan={props.plan}
          label={TextService.format(strings.MoveActionToDp_SelectDP)}
          lopGuid={lopGuid}
          dpGuid={dpGuid}
          setDpGuid={setDpGuid}
          getAdditionalItemInfo={getAdditionalDpInfo}
        />
        {LicenseService.license?.effectsEnabled &&
          <Dropdown
            label={TextService.format(strings.MoveActionToDp_SelectEffect)}
            selectedKey={effectGuid ?? NULL_KEY}
            onChange={(_, val) => setEffectGuid(val.key === NULL_KEY ? null : '' + val.key)}
            options={effectList}
          />}
      </Stack>
    </BasicDialog>);

}
