import * as React from 'react';
import { TeamsAuthScope } from 'teams/components/TeamsAuthScope';
import { TeamsAuthService } from 'teams/services/TeamsAuthService';
import { SharepointExternalDataService } from 'integrations/sharepoint';
import { env } from 'shared/clientEnv';
import { MainFrame } from 'frames/MainFrame';

import { IEnvContext } from 'services/EnvContext';
import { TokenKind } from 'shared/TokenKind';
import { PlannerConfigurationService } from 'integrations/planner';
import { ProjectDataService } from 'integrations/project';
import { DevOpsDataService } from 'integrations/devops';
import { trackClient } from 'shared/clientTelemetry';
import * as microsoftTeams from '@microsoft/teams-js';
import { ApiService } from 'services/api/ApiService';

import { UrlService } from 'shared/urlService';
import { makeGuidString } from 'shared/guid';
import { WSContext } from 'services/WSContext';

/**
 * Implementation of the VistoTeams Tab content page
 */

export const VistoTeamsTab = () => {

  const [context, setContext] = React.useState<{
    planId: string;
    envContext: IEnvContext;
  }>(null);

  const onLoad = async (envContext: IEnvContext) => {

    const planId = env.getParamFromUrl('planId');

    const oldSettings = await microsoftTeams.pages.getConfig();
    const host = 'https://' + window.location.host;
    const newSettings = TeamsAuthService.makeTabSettings(host, planId, envContext.siteUrl);
    if (oldSettings.websiteUrl !== newSettings.websiteUrl) {
      try {
        await microsoftTeams.pages.config.setConfig({ ...oldSettings, websiteUrl: newSettings.websiteUrl })
        trackClient.log(`Updated websiteUrl for plan ${planId}`);
      } catch (err) {
        trackClient.warn(`Failed to udpate websiteUrl for plan ${planId}`, err);
      }
    }

    PlannerConfigurationService.configure(envContext.tid, envContext.groupId);
    SharepointExternalDataService.configure();
    ProjectDataService.configure();
    DevOpsDataService.configure();
    ApiService.configure(() => TeamsAuthService.getTeamsToken());

    setContext({ planId, envContext });
  };

  const getRealtimeService = (userId, containerId, callback) => {
    return new WSContext(UrlService.makeInstanceId(context.envContext.siteUrl, context.planId), makeGuidString(), callback);
  }

  return (
    <TeamsAuthScope name='Teams_Tab' kind={TokenKind.sharepoint} onLoad={onLoad}>
      {context && <MainFrame planId={context.planId} siteUrl={context.envContext.siteUrl} getRealtimeService={getRealtimeService} />}
    </TeamsAuthScope>
  );
};
