
import { IButtonStyles } from "@fluentui/react/lib/Button";
import { IStackStyles } from "@fluentui/react/lib/Stack";
import { mergeStyles, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { IBasePickerStyles } from "@fluentui/react/lib/Pickers";
import { useTheme } from '@fluentui/react';

export const useTeamPickerStyles = () => { // eslint-disable-line @typescript-eslint/explicit-function-return-type

  const { palette } = useTheme();

  const renderIconButtonRemoveStyles: Partial<IButtonStyles> = {
    root: {
      height: 26,
      lineHeight: 26
    }
  };

  const renderItemStylesMulti: Partial<IStackStyles> = {
    root: {
      height: 26,
      lineHeight: 26,
      paddingLeft: 10,
      marginLeft: 5,
      marginBottom: 5,
      cursor: "default",
      backgroundColor: palette.themeLighterAlt,
      ":hover": {
        backgroundColor: palette.themeLighter,
      },
    },
  };

  const renderItemStylesSingle: Partial<IStackStyles> = {
    root: {
      height: 26,
      lineHeight: 26,
      paddingLeft: 10,
      cursor: "default",
      margin: 2,
      backgroundColor: palette.themeLighterAlt,
      ":hover": {
        backgroundColor: palette.themeLighter,
      },
    },
  };

  const pickerStylesSingle: Partial<IBasePickerStyles> = {
    root: {
      width: " 100%",
      borderRadius: 0,
      marginTop: 0
    },

    input: {
      width: "100%",
      backgroundColor: palette.white,
    },
    itemsWrapper: {

    },
    text: {
      borderStyle: "solid",
      width: "100%",
      borderWidth: 1,
      backgroundColor: palette.white,
      borderRadius: 0,
      borderColor: palette.neutralQuaternaryAlt,
      ":focus": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: palette.themePrimary,
      },
      ":hover": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: palette.themePrimary,
      },
      ":after": {
        borderWidth: 0,
        borderRadius: 0,
      },
    },
  };

  const pickerStylesMulti: Partial<IBasePickerStyles> = {
    root: {
      width: " 100%",
      borderRadius: 0,
    },

    input: {
      width: "100%",
      backgroundColor: palette.white,
    },
    itemsWrapper: {
      padding: 3,
    },
    text: {
      borderStyle: "solid",
      width: "100%",
      borderWidth: 1,
      backgroundColor: palette.white,
      borderRadius: 0,
      borderColor: palette.neutralQuaternaryAlt,
      ":focus": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: palette.themePrimary,
      },
      ":hover": {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: palette.themePrimary,
      },
      ":after": {
        borderStyle: "solid",
        borderWidth: 1,
        // borderColor: theme.neutralQuaternaryAlt,
        borderColor: palette.themePrimary,
      },
    },
  };

  const componentClasses = mergeStyleSets({
    eventCircleColor: mergeStyles({
      borderRadius: "50%",
      borderWidth: 3,
      borderStyle: "solid",
      padding: 10,
    }),
    separator: mergeStyles({
      marginTop: 25,
      marginLeft: 20,
      marginRight: 20,
      borderBottomWidth: 1,
      borderBottomColor: palette.neutralQuaternaryAlt,
      borderBottomStyle: "solid",
    }),
    filePickerButtonStyles: mergeStyles({
      position: "relative",
      top: -15,
    }),
    iconStyles: {
      paddingLeft: 2,
      fontWeight: 500,
      color: palette?.themePrimary,
    },
  });

  return {
    componentClasses,
    pickerStylesMulti,
    pickerStylesSingle,
    renderItemStylesSingle,
    renderItemStylesMulti,
    renderIconButtonRemoveStyles,
  };
};
