import { graphGet, graphPatch, graphPost, GraphQueryable } from '@pnp/graph';
import { IDrive } from '@pnp/graph/onedrive';

import '@pnp/graph/onedrive';
import '@pnp/graph/sites';
import { UrlService } from 'shared/urlService';
import { trackClient } from 'shared/clientTelemetry';
import { ExcelWorkbookService } from './ExcelWorkbookService';
import { TextService } from './TextService';
import strings from 'VistoWebPartStrings';

export class ExcelWorkbookSession {

  private drive: IDrive;
  private workbookId: string;
  private sessionId: string;

  private async createSession(workbookId: string) {
    try {
      const session = await graphPost(GraphQueryable(this.drive, `/items/${workbookId}/workbook/createSession`));
      this.sessionId = session.id;
      this.workbookId = workbookId;
    } catch (error) {
      trackClient.throw(
        TextService.format(strings.ExcelError_CreateSession, {
          message: error.message
        }),
        error
      );
    }
  }

  private async refreshSession() {
    try {
      await graphPost(GraphQueryable(this.drive, `/items/${this.workbookId}/workbook/refreshSession`), {
        body: JSON.stringify({}),
        headers: {
          'workbook-session-id': this.sessionId
        }
      });
    } catch (error) {
      trackClient.throw(
        TextService.format(strings.ExcelError_RefreshSession, {
          message: error.message
        }),
        error
      );
    }
  }

  public async close() {
    if (this.sessionId) {
      await graphPost(GraphQueryable(this.drive, `/items/${this.workbookId}/workbook/closeSession`), {
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          'workbook-session-id': this.sessionId
        }
      });
    }
  }

  public async ensureFolder(drive: IDrive, folderPath: string) {
    try {
      await graphPatch(GraphQueryable(drive, `/root:/${folderPath}`), {
        body: JSON.stringify({
          'folder': {},
          '@microsoft.graph.conflictBehavior': 'fail'
        })
      });
    } catch (error) {
      if (error.status !== 409) {
        trackClient.throw(
          TextService.format(strings.ExcelError_CreateFolder, {
            folderPath,
            message: error.message
          }),
          error
        );
      }
    }
  }

  private async createFile(folderPath: string, fileName: string) {
    try {
      const query = folderPath ? `/root:/${folderPath}:/children` : `/root/children`;
      const file = await graphPost(GraphQueryable(this.drive, query), {
        body: JSON.stringify({
          'file': {},
          'name': fileName,
          '@microsoft.graph.conflictBehavior': 'fail'
        })
      });
      return file;
    } catch (error) {
      trackClient.throw(
        TextService.format(strings.ExcelError_CreateFile, {
          fileName,
          folderPath,
          message: error.message
        }),
        error
      );
    }
  }

  public async create(siteUrl: string, excelWorkbook: string) {

    this.drive = await ExcelWorkbookService.getDrive(siteUrl, 'short');

    const path = excelWorkbook.split('/');
    const folderPath = path.slice(0, path.length - 1).join('/');
    if (folderPath) {
      await this.ensureFolder(this.drive, folderPath);
    }

    const fileName = path[path.length - 1];
    const file = await this.createFile(folderPath, fileName);

    await this.createSession(file.id);
    await this.refreshSession();
    return UrlService.combine(folderPath, file.name);
  }

  public async open(siteUrl: string, excelOptions: IkeyResultExcelOptions) {
    this.drive = await ExcelWorkbookService.getDrive(siteUrl, 'short');

    const file = await ExcelWorkbookService.getFile(this.drive, excelOptions);

    await this.createSession(file.id);
    await this.refreshSession();
  }

  public async get(query: string) {
    return await graphGet(GraphQueryable(this.drive, `/items/${this.workbookId}/workbook/${query}`), {
      headers: {
        'workbook-session-id': this.sessionId
      }
    });
  }

  public async post(query: string, body: any) {
    return await graphPost(GraphQueryable(this.drive, `/items/${this.workbookId}/workbook${query}`), {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'workbook-session-id': this.sessionId
      }
    });
  }

  public async patch(query: string, body: any) {
    return await graphPatch(GraphQueryable(this.drive, `/items/${this.workbookId}/workbook${query}`), {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'workbook-session-id': this.sessionId
      }
    });
  }
}
