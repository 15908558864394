import { VistoKind, addListDefinition } from '.';
import { FieldGenerator } from './common/FieldGenerator';
import { IFieldDefinitions, IVistoListItem, ListItemUpdater, IItemWithAttachments, IFieldValueUrl } from './common';

export type IPlanItems = { [key: string]: IVistoListItem };

export interface IVistoPlan extends IItemWithAttachments {
  readonly siteUrl: string;
  readonly planId: string;

  readonly planVersion?: string;
  readonly itemId?: number;
  readonly name?: string;
  readonly description?: string;

  revision?: number;
  drawingRevision?: number;
  statusDate?: Date;

  readonly editable?: boolean;

  readonly esName?: string;
  readonly esDescription?: string;

  readonly navigationLink?: IFieldValueUrl;

  drawingXml?: string; // todo: move to a separate file
  styleJson?: string;
  commentsJson?: string;
  previewPng?: string;
  licenseKey?: string;
  settingsJson?: string;

  readonly items?: IPlanItems;

  readonly timeZoneBias?: number;

  createdDate?: Date;
  modifiedDate?: Date;
  etag?: string;
}

export const VistoPlanListFields: IFieldDefinitions<IVistoPlan> = {
  itemId: FieldGenerator.makeIdField('VISTO_Plan_ItemId'),
  name: FieldGenerator.makeTitleField('VISTO_Plan_Name'),
  description: FieldGenerator.makeDescriptionField('VISTO_COA_Description'),
  links: FieldGenerator.makeLinksField('VISTO_Plan_Links'),
  esName: FieldGenerator.makeTextField('VISTO_Plan_ES_Name', 'EndStateName', true),
  esDescription: FieldGenerator.makeNoteField('VISTO_Plan_ES_Description', 'EndStateDescription', true, true),
  navigationLink: FieldGenerator.makeUrlField('VISTO_Plan_NavigationLink', 'NavigationLink', true),
  planId: FieldGenerator.makeTextField('VISTO_Plan_Id', 'VISTO_Plan_Id', false),
  drawingXml: FieldGenerator.makeNoteField('VISTO_Plan_XmlData', 'VISTO_XmlData', false, false),
  styleJson: FieldGenerator.makeNoteField('VISTO_Plan_DefaultXml', 'VISTO_DefaultXml', false, false),
  commentsJson: FieldGenerator.makeNoteField('VISTO_Plan_Comments', 'VISTO_Comments', false, false),
  planVersion: FieldGenerator.makeTextField('VISTO_Plan_Version', 'VISTO_Version', false),
  licenseKey: FieldGenerator.makeNoteField('VISTO_Plan_License', 'VISTO_License', false, false),
  settingsJson: FieldGenerator.makeNoteField('VISTO_Plan_Settings', 'VISTO_Settings', false, false),
  createdDate: FieldGenerator.makeCreatedField('VISTO_Plan_Created'),
  modifiedDate: FieldGenerator.makeModifiedField('VISTO_Plan_Modified'),
  previewPng: FieldGenerator.makeNoteField('VISTO_Plan_Preview', 'PreviewPng', false, false),
};

addListDefinition({

  kind: VistoKind.Plan,
  fields: VistoPlanListFields,
  enableVersioning: false,

  defaultViewXml: `<View>
    <Query />
    <ViewFields>
      <FieldRef Name='LinkTitle' />
      <FieldRef Name='${VistoPlanListFields.description.name}' />
      <FieldRef Name='${VistoPlanListFields.esName.name}' />
      <FieldRef Name='${VistoPlanListFields.esDescription.name}' />
    </ViewFields>
    <RowLimit Paged='TRUE'>30</RowLimit>
  </View>`,

  setItemSpecificFields: (updater: ListItemUpdater, spItem, update: Partial<IVistoPlan>) => {
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'planId');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'name');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'description');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'links');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'esName');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'esDescription');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'drawingXml');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'styleJson');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'commentsJson');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'planVersion');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'licenseKey');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'settingsJson');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'navigationLink');
    updater.setItemSpField(VistoPlanListFields, spItem, update, 'previewPng');
  },

  getItemSpecificFields: (updater: ListItemUpdater, item: Partial<IVistoPlan>, spItem) => {
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'planId');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'name');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'description');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'links');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'esName');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'esDescription');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'drawingXml');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'planVersion');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'styleJson');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'commentsJson');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'licenseKey');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'settingsJson');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'navigationLink');
    updater.getItemSpField(VistoPlanListFields, item, spItem, 'previewPng');
  }
});

