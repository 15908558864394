import { Stack } from '@fluentui/react';
import { Placeholder } from '@pnp/spfx-controls-react/lib/controls/placeholder';
import React from 'react';
import { TextService } from 'services/TextService';
import strings from 'VistoWebPartStrings';
import { InfoBar } from './InfoBar';

export const PlaceholderAuthError = (props: {
  disabled: boolean;
  onConfigure: () => Promise<void>;
  authError?: any;
}) => {

  return (
    <Stack grow>
      <InfoBar {...props.authError} />
      <Placeholder
        iconName='LaptopSecure'
        iconText={TextService.format(strings.AuthContext_AuthError)}
        description={TextService.format(strings.AuthContext_AuthErrorExplanation)}
        buttonLabel={TextService.format(strings.AuthContext_AuthErrorAction)}
        disableButton={props.disabled}
        onConfigure={props.onConfigure}
      />
    </Stack>
  );
}
