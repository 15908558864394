import { CellKind } from './CellKind';
import { env } from './clientEnv';
import { VistoKind } from './VistoKind';
import { ISelectedCellInfo } from './ISelectedCellInfo';

export class UrlService {

  public static LOCAL_URL = 'local:local';

  public static combine(left: string, right: string) {
    if (!right) return left;
    if (!left) return right;
    return left.replace(/\/+$/, '') + '/' + right.replace(/^\/+/, '');
  }

  public static getOrigin(url: string) {
    return url && !UrlService.isLocalUrl(url) && new URL(url).origin;
  }

  public static haveSameOrigin(a: string, b: string) {
    return UrlService.getOrigin(a) === UrlService.getOrigin(b);
  }

  public static makeInstanceId(siteUrl: string, planId: string): string {
    return UrlService.combine(siteUrl, planId);
  };

  public static getDomain(url: string) {
    return url && new URL(url).host;
  }

  public static getPathName(url: string) {
    return url && new URL(url).pathname;
  }

  public static isLocalUrl(siteUrl: string) {
    return siteUrl && siteUrl.startsWith(UrlService.LOCAL_URL);
  };

  public static getListName(kind: VistoKind, planId: string) {
    return kind === VistoKind.Plan ? `Visto_Plans` : `Visto_${VistoKind[kind]}_${planId}`;
  }

  public static getListRelativeUrl(plan: { siteUrl: string, planId: string }, kind: VistoKind = VistoKind.Plan) {
    const listName = UrlService.getListName(kind, plan.planId);
    const listRelativeUrl = UrlService.combine('Lists', listName);
    const siteRelativeUrl = UrlService.getPathName(plan.siteUrl);
    return UrlService.combine(siteRelativeUrl, listRelativeUrl);
  }

  public static makeSitePagesListUrl(siteUrl: string) {
    return UrlService.combine(siteUrl, 'SitePages');
  }

  public static makePageUrl(siteUrl: string, fileName: string): string {
    return UrlService.combine(UrlService.makeSitePagesListUrl(siteUrl), `${fileName}.aspx`);
  }

  public static makePlanDeepLink(entityId: string, channelId: string, subEntityId: string): string {
    const appId = env.getTemplateParamValue('APPLICATION_ID');
    const encodedContext = encodeURI(`{\x22subEntityId\x22:\x22${subEntityId}\x22,\x22channelId\x22:\x22${channelId}\x22}`);
    const result = `https://teams.microsoft.com/l/entity/${appId}/${entityId}?context=${encodedContext}`;
    return result;
  }

  public static makeChannelDeepLink(channelId: string): string {
    const result = `https://teams.microsoft.com/l/channel/${channelId}`;
    return result;
  }

  public static makeAppDeepLink(): string {
    const appId = env.getTemplateParamValue('APPLICATION_ID');
    const result = `https://teams.microsoft.com/l/app/${appId}`;
    return result;
  }

  public static parseSubEntityId(subEntityId: string): ISelectedCellInfo {
    if (subEntityId) {
      const items = subEntityId.split('|');
      const cellKind = CellKind[items[0]];
      const cellId = items[1];
      const guid = items[items.length - 1];
      return { cellKind, cellId, guid };
    }
  }

  public static makeSubEntityId(selection: ISelectedCellInfo) {
    return selection ? `${CellKind[selection.cellKind]}|${selection.cellId}|${selection.guid}` : null;
  }

  public static get isDev() {
    const hostname = document?.location?.hostname;
    return hostname?.startsWith('localhost') || hostname?.startsWith('dev');
  }

}
