import * as React from 'react';
import { MainFrame } from 'frames/MainFrame';
import { WebAuthScope } from 'teams/components/WebAuthScope';
import { TokenKind } from 'shared/TokenKind';
import { useParams } from 'react-router-dom';
import { IEnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';

export const LocalPlan = (props: {
}) => {

  // const planId = env.getParamFromUrl('planId');

  const params = useParams();
  const planId = params.planId;
  const siteUrl = UrlService.LOCAL_URL;

  const onLoad = async (ctx: IEnvContext) => {
    // await UserInfoService.configure();
  };

  return (
    <WebAuthScope name='Web_LocalPlan' kind={TokenKind.api} onLoad={onLoad} siteUrl={siteUrl}>
      <MainFrame planId={planId} siteUrl={siteUrl} getRealtimeService={() => null} />
    </WebAuthScope>
  );
};
