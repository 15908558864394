import { EditFocusDialog } from 'dialogs';
import * as React from 'react';
import { IVistoPlan, VistoFocusItem, VistoKind } from 'sp';
import { MenuItems } from 'frames/TopFrame/MenuItems';
import { SidebarFocusItem } from './SidebarFocusItem';
import { Container, DropResult } from '@edorivai/react-smooth-dnd';
import { makeGuidString } from 'shared/guid';
import { ISidebarProps } from './common/ISidebarProps';
import { SidebarBase } from './common/SidebarBase';
import { AppContext } from 'services/AppContext';
import { Commands } from 'services/Commands';
import { PlanDataService } from 'services/PlanDataService';
import { useSidebarShareDialog } from './SidebarShareHook';
import { LicenseService } from 'services/LicenseService';
import { CellKind } from 'shared/CellKind';

interface ISidebarFocusProps extends ISidebarProps {
  plan: IVistoPlan;
}

export function SidebarFocus(props: ISidebarFocusProps) {

  const { dispatchCommand, isPlanEditEnabled, isPopupOpen, isPlanLive, isPlanLocal, setIsPopupOpen, propertyBag, setPropertyBag, notify } = React.useContext(AppContext);

  const [newFocusDialogVisible, setNewFocusDialogVisible] = React.useState(false);

  React.useEffect(() => {
    setIsPopupOpen(newFocusDialogVisible);
  }, [newFocusDialogVisible]);

  const isEditDisabled = !isPlanEditEnabled || isPopupOpen;
  const isHistoryDisabled = !isPlanLive || isPlanLocal;

  const menuItems = [
    MenuItems.getNewFocusMenuItem(isEditDisabled, () => setNewFocusDialogVisible(true)),
  ];

  const farItems = [
    MenuItems.getShowMenu([
      MenuItems.getShowValidationsMenu(propertyBag, setPropertyBag),
      MenuItems.getShowProgressMenu(propertyBag, setPropertyBag),
      MenuItems.getShowAssigneesMenu(propertyBag, setPropertyBag),
      MenuItems.getShowHistoryMenu(isHistoryDisabled, propertyBag, setPropertyBag),
      MenuItems.getDividerMenuItem(4),
      MenuItems.getShowAdvisoryMenu(propertyBag, setPropertyBag)
    ])
  ];

  const defaultFocuses = PlanDataService.getItems<VistoFocusItem>(props.plan.items, VistoKind.Focus);
  const [focuses, setFocuses] = React.useState(defaultFocuses);
  React.useEffect(() => setFocuses(defaultFocuses), [props.plan.items]);

  const onDrop = (dropResult: DropResult) => {
    const reordered = Commands.getReorderedItems(focuses, dropResult.removedIndex, dropResult.addedIndex);
    setFocuses(reordered);
    const command = Commands.makeChangeSortOrderCommand(reordered, notify);
    if (command) {
      dispatchCommand(command, { wrap: true });
    }
  };

  const [ShareLinkDialog, setShareLinkDialogVisible] = useSidebarShareDialog();

  const overflowMenuItems = [
    ...(props.overflowMenuItems ?? []),
    MenuItems.GetTeamsLinkMenuItem(() => setShareLinkDialogVisible(true, { cellKind: CellKind.FOCUS, cellId: null, guid: null })),
  ];

  const newFocus: VistoFocusItem = {
    kind: VistoKind.Focus,
    guid: makeGuidString(),
    active: false,
    sortOrder: focuses.length
  };

  return <SidebarBase
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    menuItems={menuItems}
    overflowMenuItems={overflowMenuItems}
    farItems={farItems}
    advisoryTopicKey='Focus'
    content={
      <>
        {focuses.length > 0 && <Container getGhostParent={() => document.body} onDrop={onDrop} getChildPayload={i => focuses[i]} >
          {focuses.map(focus => <SidebarFocusItem key={focus.guid} plan={props.plan} focus={focus} isCurrent={focus.active} />)}
        </Container>}
        {newFocusDialogVisible && <EditFocusDialog onDismiss={() => setNewFocusDialogVisible(false)} plan={props.plan} focus={newFocus} />}
        {ShareLinkDialog}
      </>
    }
  />;
}
