import { 
  IWSItemOperationInfo, 
  IWSMessage, 
  IWSPlanEdit, 
  IWSPlanRevision, 
  IWSPlanSelect, 
  WSMessageType} from 'shared/ws';
import { Operation } from 'shared/Operation';
import { trackClient } from 'shared/clientTelemetry';
import { parseJSON } from 'shared/parse';
import { VistoKind } from 'sp';
import { ApiService } from './api/ApiService';
import { IRealtimeService } from './IRealtimeService';

export class WSContext implements IRealtimeService {

  private pingTimeout;
  private pongTimeout;
  private connectTimeout;
  private reconnectTimeout;

  public instanceId: string;
  public connectionId: string;
  private socket: WebSocket;
  private terminated: boolean = false;
  private reconnectCount: number = 0;

  private send(req: any) {
    if (this.socket) {
      this.reconnectCount = 0;
      const json = JSON.stringify(req);
      try {
        if (this.terminated) {
          trackClient.warn(`[WS CLIENT] socket is terminated, ignoring the message`);
        } else switch (this.socket.readyState) {
          case WebSocket.CONNECTING:
            trackClient.warn(`[WS CLIENT] socket is connecting, ignoring the message`);
            break;
          case WebSocket.CLOSING:
            trackClient.warn(`[WS CLIENT] socket is closing, ignoring the message`);
            break;
          case WebSocket.CLOSED:
            this.connect().then(() => {
              this.socket.send(json);
            });
            break;
          case WebSocket.OPEN:
            this.socket.send(json);
            break;
        }
      } catch (err) {
        trackClient.error(`[WS CLIENT] error happend, reconnecting`, err);
        this.connect().then(() => {
          this.socket.send(json);
        })
      }
    }
  }

  public notifyItemOperation(operation: Operation, planId: string, kind: VistoKind, guid: string) {
    const req: IWSItemOperationInfo = {
      type: WSMessageType.item,
      planId: planId,
      kind: kind,
      guid: guid,
      operation: operation
    };

    this.send(req);
  }

  public setEditor(val: boolean): void {
    if (this.connectionId) {
      const req: IWSPlanEdit = {
        type: WSMessageType.edit,
        set: val,
      };

      this.send(req);
    }
  }

  public setSelection(val: string) {

    if (this.connectionId) {
      const req: IWSPlanSelect = {
        type: WSMessageType.select,
        shape: val,
      };

      this.send(req);
    }
  }

  public setDrawingRevision(drawingRevision: number) {

    if (this.connectionId) {
      const req: IWSPlanRevision = {
        type: WSMessageType.drawingRevision,
        drawing_revision: drawingRevision
      };

      this.send(req);
    }
  }

  public realtimeUpdate: (msg: IWSMessage) => void;

  constructor(instanceId: string, connectionId: string, realtimeUpdate: (msg: IWSMessage) => void) {
    this.instanceId = instanceId;
    this.connectionId = connectionId;
    this.realtimeUpdate = realtimeUpdate;
  }

  private close() {
    switch (this.socket?.readyState) {
      case WebSocket.OPEN:
      case WebSocket.CONNECTING:
        console.debug('[WS CLIENT] closing the socket');
        this.socket.close();
        break;
    }
  }

  private ping() {
    if (!this.terminated) {
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.send('__ping__');
      }
      clearTimeout(this.pingTimeout);
      this.pingTimeout = setTimeout(() => {
        if (!this.terminated) {
          console.warn('[WS CLIENT] ping timeout, restarting the socket');
          this.close();
        }
      }, 20000);
  }
}

  public async connect(editor?: boolean) {

    try {
      clearTimeout(this.pingTimeout);
      clearTimeout(this.pongTimeout);
      clearTimeout(this.connectTimeout);
      clearTimeout(this.reconnectTimeout);
      
      const token = await ApiService.getIdToken();
      const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
      const url = `${protocol}//${window.location.host}?instance_id=${encodeURIComponent(this.instanceId)}&connection_id=${this.connectionId}&access_token=${token}&editor=${editor ? 'true' : 'false'}`;

      trackClient.debug(`[WS CLIENT] opening connection`, this.instanceId);

      this.socket = new WebSocket(url);

      // set up ping-pong
      this.socket.addEventListener('open', ev => {
        this.connectTimeout = setTimeout(() => this.ping(), 30000);
      });

      this.socket.addEventListener('error', ev => {
        trackClient.error(`[WS CLIENT] a socket error occured`, ev);
        this.close();
      });

      const self = this;
      this.socket.addEventListener('close', function connect(ev) {
        trackClient.warn(`[WS CLIENT] connection closed`, ev);
    
        if (self.realtimeUpdate) {
          self.realtimeUpdate({ type: WSMessageType.disconnected });
        }
    
        if (!self.terminated) {
          self.reconnectTimeout = setTimeout(() => {
            if (!self.terminated) {
              self.socket.removeEventListener('close', connect);
              self.close();
              if (++self.reconnectCount < 20) {
                trackClient.warn(`[WS CLIENT] trying to reconnect, attempt ${self.reconnectCount} of 20`, ev);
                self.connect();
              }
            }
          }, 10000);
        }
      });

      this.socket.addEventListener('message', (msg) => {

        if (msg.data === '__pong__') {
          clearTimeout(this.pingTimeout);
          this.pongTimeout = setTimeout(() => this.ping(), 30000);
          return;
        }

        const data = parseJSON(msg.data) as IWSMessage;

        trackClient.debug(`[WS CLIENT] ${WSMessageType[data.type]}`, msg);

        switch (data.type) {
          case WSMessageType.status:
          case WSMessageType.item:
            if (this.realtimeUpdate)
              this.realtimeUpdate(data);
            break;

          default:
            trackClient.warn(`[WS CLIENT]: unknown message ${msg.data} (ignored)`);
            break;
        }
      });
    } catch (error) {
      trackClient.error(`[WS CLIENT] connection failed`, error);
    }
  };

  disconnect() {
    if (this.socket) {
      console.debug('[WS CLIENT] disconnecting');
      clearTimeout(this.pingTimeout);
      clearTimeout(this.pongTimeout);
      clearTimeout(this.connectTimeout);
      clearTimeout(this.reconnectTimeout);
      this.terminated = true;
      this.close();
      this.socket = null;
    }
  }
}
